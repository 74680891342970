<template>
  <div v-if="isPermission">
    <select-shop :loadingButton="loadingButton" v-on:getData="loadData"></select-shop>
    <CCard class="shadow-sm">
      <CCardBody>
        <div class="row">
          <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-center">
          <h2 id="taxBillSalesTitle" class="mb-0 font-weight-normal">
            {{ $t("taxBillSalesTXT") }}
          </h2>
          <HelpButton :isHeader="false" class="ml-2"></HelpButton>
          </div>
          <div class="col-md-2 col-sm-3 text-right text-success">
            <CButton id="taxBillSalesExportButton" v-if="isExport" v-on:click="exportReport()" block color="info">
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
            </CButton>
          </div>
        </div>
        <CRow>
          <CCol sm="12" lg="12">
            <show-date></show-date>
            <hr />
            <div style="overflow-x: auto">
              <DataTable
                style="white-space: nowrap"
                :items="items()"
                :fields="fields"
                hover
                border
              >
                <!-- <template #print="{ item }">
                  <td class="py-2 text-center">
                    <CButton
                      color="primary"
                      variant="outline"
                      square
                      size="sm"
                      @click="printInvoice(item.objectId)"
                    >
                      <i class="fa fa-print"></i>
                    </CButton>
                  </td>
                </template> -->
              </DataTable>
            </div>
          </CCol>
        </CRow>
        <pagination :meta_data="meta_data" v-on:next="loadData">
        </pagination>
      </CCardBody>
    </CCard>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import report from "@/services/report";
import util from "@/util/util";
import DataTable from "@/containers/DataTable";
import permis from "@/util/permission";
import { fetchproductTaxData, exportproductTaxBillReport } from "@/controller/report/productTaxController";
import axios from '@/services/service'
import transaction from '@/model/report/transaction'
import Pagination from '@/containers/Pagination'
import HelpButton from "../../containers/HelpButton.vue";

export default {
  components: {
    Pagination,
    DataTable,
    HelpButton
  },
  data() {
    return {
      isLoading: false,
      loadingButton: false,
      tableData: [],
      details: [],
      orderDetail: {},
      data:[],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
    };
  },
  computed: {
    ...mapGetters(["shops", "date", "users", "permissions"]),
    isPermission() {
      return permis.findPermissionRead("report", this.$route.path);
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    uid() {
      return `${localStorage.getItem("shopsUid")}`;
    },
    startDate() {
      return moment(String(this.date.start)).format("YYYY-MM-DD");
    },
    endDate() {
      return moment(String(this.date.end)).format("YYYY-MM-DD");
    },
    fields() {
      return [
        {
          key: "payReceiptAt",
          label: this.$i18n.t("date"),
          _classes: "text-left text-dark font-weight-normal",
        },
        {
          key: "receiptNumber",
          label: this.$i18n.t("receiptNumber"),
          _classes: "text-left text-dark font-weight-normal",
        },
        {
          key: "isVATIncluded",
          label: this.$i18n.t("vatType"),
          _classes: "text-left text-dark font-weight-normal",
        },
        {
          key: "netTotalIncVAT",
          label: this.$i18n.t("netTotalIncVATDiscount"),
          _classes: "text-right text-dark font-weight-normal",
        },
        {
          key: "serviceCharge",
          label: this.$i18n.t("serviceCharge"),
          _classes: "text-right text-dark font-weight-normal",
        },
        {
          key: "netAmountVATTotal",
          label: this.$i18n.t("netTotalBeforeVat"),
          _classes: "text-right text-dark font-weight-normal",
        },
        {
          key: "vatAmount",
          label: this.$i18n.t("vat"),
          _classes: "text-right text-dark font-weight-normal",
        },
        {
          key: "totalAmountWithVAT",
          label: this.$i18n.t("totalITemVat"),
          _classes: "text-right text-dark font-weight-normal",
        },
        {
          key: "taxInvoice",
          label: this.$i18n.t("taxInvoiceReceipt"),
          _classes: "text-left text-dark font-weight-normal",
        },
        // {
        //   key: "print",
        //   label: this.$i18n.t(""),
        //   _classes: "text-left text-dark font-weight-normal",
        // },
      ];
    },
  },
  created() {
    this.loadData();
  },
  mounted() {
    this.loadData();
  },
  methods: {
  async loadData(page = 1) {
    this.isLoading = true;
    try {
      const data = await fetchproductTaxData(this.startDate, this.endDate, page);
      this.tableData = data.processedData;
      const clonedMetaData = JSON.parse(JSON.stringify(data.metaData));
      this.meta_data.last_page = clonedMetaData.pageCount
      this.meta_data.current_page = clonedMetaData.currentPage
      this.meta_data.itemCount = clonedMetaData.itemCount
      this.meta_data.limit = clonedMetaData.limit
      this.meta_data.items = clonedMetaData.items
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      this.isLoading = false;
      this.loadingButton = true;
    }
  },
  items() {
    if (this.tableData.length > 0) {
      return this.tableData.map(item => ({
        payReceiptAt: item.payReceiptAt !== "-" ? moment(item.payReceiptAt).format("DD/MM/YYYY HH:mm") : "-",
        receiptNumber: item.receiptNumber,
        isVATIncluded: item.isVATIncluded === false ? this.$t('isnotVatIncluded') : this.$t('isVatIncluded'),
        netTotalIncVAT: util.convertCurrency(item.netTotalIncVAT),
        serviceCharge: util.convertCurrency(item.serviceCharge),
        netAmountVATTotal: util.convertCurrency(item.netAmountVATTotal),
        vatAmount: util.convertCurrency(item.vatAmount),
        totalAmountWithVAT: util.convertCurrency(item.totalAmountWithVAT),
        taxInvoice: item.taxInvoice,
        objectId: item.objectId,
      }));
    } else {
      return [];
    }
  },
  async exportReport() {
    try {
      const response = await exportproductTaxBillReport(this.startDate, this.endDate, this.$i18n.locale);
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        const filename = this.$t('taxBillSalesTXT') + '.xlsx';
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      console.error('Error exporting report:', error);
    }
  },
  printInvoice(objectId) {
      this.$router.push(`/report/taxInvoice/${objectId}`);
    },
}

};
</script>
