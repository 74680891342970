const productTaxModel = require("../../model/report/productTax");

exports.fetchproductTaxData = async (startDate, endDate, page) => {
  try {
    let productTaxBillData = await productTaxModel.productTaxBill(startDate, endDate, page);
    const processedData = productTaxBillData.data.map(item => ({
      objectId: item.objectId,
      payReceiptAt: item.payReceiptAt ? item.payReceiptAt : "-",
      receiptNumber: item.receiptNumber ? item.receiptNumber : "-",
      isVATIncluded: item.isVATIncluded ? item.isVATIncluded : "-",
      netTotalIncVAT: item.netTotalIncVAT ? item.netTotalIncVAT : "-",
      serviceCharge: item.serviceCharge ? item.serviceCharge : "-",
      netAmountVATTotal: item.netAmountVATTotal ? item.netAmountVATTotal : "-",
      vatAmount: item.vatAmount ? item.vatAmount : "-",
      totalAmountWithVAT: item.totalAmountWithVAT ? item.totalAmountWithVAT : "-",
      taxInvoice: item.taxInvoice ? item.taxInvoice : "-"
    }));
    // processedData.sort((a, b) => {
    //   const dateA = new Date(a.payReceiptAt);
    //   const dateB = new Date(b.payReceiptAt);
    //   return dateB - dateA;
    // });
    const metaData = {
      items: productTaxBillData.paginate.items || 0,
      pageCount: productTaxBillData.paginate.pageCount,
      currentPage: productTaxBillData.paginate.currentPage,
      itemCount: productTaxBillData.paginate.itemCount,
      limit: productTaxBillData.paginate.perPage
    };
    return {processedData, metaData};
  } catch (error) {
    console.error('Error fetching table data:', error);
    return null;
  }
}

exports.exportproductTaxBillReport = async (startDate, endDate, locale) => {
    try {
        const result = await productTaxModel.exportproductTaxBillReport(startDate, endDate, locale);
        return result;
    } catch (error) {
        console.error('Error exporting Option Sales by Cost Report:', error);
        return null;
    }
  }
