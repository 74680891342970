const moment = require('moment-timezone');
const axios = require('axios');

async function productTaxBill(startDate, endDate, page) {
    const token = `${localStorage.getItem('token')}`
    const uid = `${localStorage.getItem('shopsUid')}`
    const shopObjectId = `${localStorage.getItem('shopObjectId')}`
    const startAt = moment(String(startDate)).format('YYYY-MM-DD')
    const endAt = moment(String(endDate)).format('YYYY-MM-DD')
    const headers = {
        Authorization: token,
        shopObjectId: shopObjectId,
    }
    const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        page: page
    }
    try {
        const res = await axios({
            baseURL: process.env.VUE_APP_SERVICE_URL,
            url: '/api/v1.0/' + uid + '/receipt/tax/bill',
            headers: headers,
            params: params,
            method: 'GET',
        })

        return res.data
    } catch (error) {
        // Handle errors
        console.log(error)
        return null
    }
}

async function exportproductTaxBillReport(startDate, endDate, locale) {
    const token = `${localStorage.getItem('token')}`
    const uid = `${localStorage.getItem('shopsUid')}`
    const shopObjectId = `${localStorage.getItem('shopObjectId')}`
    const startAt = moment(String(startDate)).format('YYYY-MM-DD')
    const endAt = moment(String(endDate)).format('YYYY-MM-DD')
    const headers = {
        Authorization: token,
        shopObjectId: shopObjectId,
    };
    const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        lang: locale
    };
    try {
        const res = await axios({
            baseURL: process.env.VUE_APP_SERVICE_URL,
            url: '/api/v1.0/' + uid + '/receipt/tax/bill/excel',
            params: params,
            headers: headers,
            method: 'GET',
            responseType: 'blob', // important for excel export
        });
        return res.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export { productTaxBill, exportproductTaxBillReport }